<template>
  <v-container
		class="fill-height flex-column justify-space-between px-0 pt-0"
		fluid
	>
    <v-list width="100%" class="nobg pt-0">
      <v-list-item-group>
        <v-divider></v-divider>
        <v-list-item class="px-6" @click.stop="localeDialog = true">
          <v-list-item-icon>
            <v-icon>language</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ locales['choose-language'] }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="text-uppercase">
            {{ locale.id }}
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="px-6">
          <v-list-item-icon>
            <v-icon>brightness_medium</v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="darkMode = !darkMode">
            <v-list-item-title>{{ locales['dark-theme'] }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="darkMode" color="primary"></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="px-6" to="/change-pass">
          <v-list-item-icon>
            <v-icon>vpn_key</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ locales['change-password'] }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>chevron_right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <Email2Fa />
        <v-divider></v-divider>
        <messages />
        <v-divider></v-divider>
        <mails />
        <v-divider></v-divider>
      </v-list-item-group>
    </v-list>

    <div class="w-100 pa-5">
    <v-btn
      rounded
      outlined
      large
      text
      color="error"
      @click="dialogDelete = true"
    >
      <v-icon left>
        clear
      </v-icon>
      {{ locales['del-user'] }}
    </v-btn>
  </div>

    <locale :dialog="localeDialog" v-on:close-locale="localeDialog = false" />

    <v-dialog
      v-model="dialogDelete"
      max-width="290"
      persistent
    >
      <v-card>
        <v-card-text>
          <p class="pt-4 text-subtitle-1">{{ locales['del-user-confirm'] }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialogDelete = false"
          >
            {{ locales['cancel'] }}
          </v-btn>
          <v-btn
            color="error"
            text
            :loading = deleting
            @click="deleteAccount"
          >
            {{ locales['no-permission'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Locale from '@/components/Locale.vue';
import Messages from '@/components/Messages.vue';
import Mails from '@/components/Mails.vue';
import Email2Fa from '../components/Email2Fa.vue';
export default {
  components: {
    Locale, Messages, Mails, Email2Fa
  },
  data () {
    return {
      localeDialog: false,
      dialogDelete: false,
      deleting: false
    }
  },
  computed: {
    ...mapGetters('profile', ['dark']),
    ...mapGetters('locales', ['locales', 'locale']),
    darkMode: {
      get: function() {
        return this.dark;
      },
      set: function(val) {
        this.$vuetify.theme.dark = val === true;
        this.setDark(val);
      }
    },
  },
  mounted() {
    this.setPageTitle(this.locales['my-profile']);
    this.setup();
  },
  beforeDestroy() {
    this.setPageTitle(undefined);
  },
  methods: {
    ...mapActions('profile', ['getSettings', 'setDark', 'deleteProfile', 'logout']),
    ...mapMutations(['setPageTitle']),
    deleteAccount() {
      this.deleting = true

      this.deleteProfile()
      .then(data => {
        this.deleting = false;
        this.dialogDelete = false;
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') })
        } else {
          this.logoutUser()
        }
      })
    },
    logoutUser() {
      this.logout();
      this.$router.push({ path: '/' })
    },
    setup() {
      this.getSettings()
      .then((data) => {
        if (data.error) {
          this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
        } 
      })
    },
  }
}
</script>
