<template>
  <v-list-item @click="mailActive = !mailActive" class="px-6">
    <v-list-item-icon>
      <v-icon>lock</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ locales['2fa'] }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-switch v-model="mailActive" readonly color="primary"></v-switch>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      isActive: false,
    }
  },
  computed: {
    ...mapGetters('locales', ['locales', 'locale']),
    ...mapGetters('profile', ['email2fa']),
    mailActive: {
      get: function() {
        return this.isActive;
        // return this.email2fa === 1
      },
      set: function(val) {
        this.isActive = val
        this.saveEmail2fa({
          email2fa : this.isActive ? '1' : '0'
        })
        .then((data) => {
          if (data.error) {
						this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
            this.isActive = !val
					} else {
            this.isActive = (data['email-2fa'] === 1 || data['email-2fa'] === '1') ? true : false
            console.log(data['email-2fa'])
          }
        })
      }
    },
  },
  watch: {
    email2fa() {
      this.isActive = this.email2fa === 1
    }
  },
  mounted() {
    this.isActive = this.email2fa === 1
  },
  methods: {
    ...mapActions('profile', ['saveEmail2fa']),
  }
}
</script>