import users from '../../api/users'

const state = () => ({
  email: undefined,
  token: undefined,
  token2fa: undefined,
  dark: true,
  locale: 'pl',
  emailNotification: 0,
  email2fa: 0,
  loading: false
})

const getters = {
  logged(state) {
    return (state.token) ? true : false 
  },
  dark(state) {
    return state.dark === true
  },
  locale(state) {
    return state.locale
  },
  emailNotification(state) {
    return state.emailNotification
  },
  email2fa(state) {
    return state.email2fa
  },
  loading(state) {
    return state.loading
  }
}

const mutations = {
  setEmail: (state, data) => {
    state.email = data
  },
  setToken: (state, data) => {
    state.token = data
  },
  setToken2fa: (state, data) => {
    state.token2fa = data
  },
  setDark: (state, data) => {
    state.dark = data === true
  },
  setLocale: (state, data) => {
    state.locale = data
  },
  setLoading: (state, data) => {
    state.loading = data
  },
  restore: (state, data) => {
    if (data.locale) {
      state.email = data.email;
      state.token = data.token;
      state.dark = data.dark;
      state.locale = data.locale;
    }
  },
  setEmailNotification: (state, data) => {
    state.emailNotification = data
  },
  setEmail2fa: (state, data) => {
    state.email2fa = data
  }
}

const actions = {
  login({commit, state}, {email, password, lang}) {
    commit('setLoading', true);
    return users.login(email, password, lang)
    .then(data => {
      if (!(typeof data['2FA'] !== 'undefined' && parseInt(data['2FA']) === 1)) {
        commit('setEmail', data.email);
        commit('setToken', data.token);
        users.store(state);
      } else {
        commit('setEmail', data.email);
        commit('setToken2fa', data.token);
        users.store(state);
      }
      commit('setLoading', false);
      return data;
    })
  },
  login2fa({commit, state}, {code}) {
    commit('setLoading', true);
    return users.login2fa(code)
    .then(data => {
      commit('setEmail', data.email);
      commit('setToken', data.token);
      users.store(state);
      
      commit('setLoading', false);
      return data;
    })
  },
  register({commit, state}, {email, password, password2}) {
    commit('setLoading', true);
    return users.create(email, password, password2)
    .then(data => {
      commit('setEmail', data.email);
      commit('setToken', data.token);
      users.store(state);
      commit('setLoading', false);
      return data;
    })
  },
  restorePofile({commit}) {
    return new Promise((resolve) => {
      commit('restore', users.restore());
      resolve();
    })
  },
  setDark({commit, state}, val) {
    commit('setDark', val);
    users.store(state);
  },
  setLocale({commit, state}, val) {
    commit('setLocale', val);
    users.store(state);
  },
  logout({commit}) {
    commit('restore', users.logout());
  },
  remind({commit}, {email}) {
    commit('setLoading', true);
    return users.remind(email)
    .then(data => {
      commit('setLoading', false);
      return data;
    })
  },
  confirm({commit}, {token}) {
    commit('setLoading', true);
    return users.confirm(token)
    .then(data => {
      commit('setLoading', false);
      return data;
    })
  },
  newpass({commit}, {password, password2, token}) {
    commit('setLoading', true);
    return users.newpass(password, password2, token)
    .then(data => {
      commit('setLoading', false);
      return data;
    })
  },
  changePass({commit}, {password, password2, passwordOld}) {
    commit('setLoading', true);
    return users.changepass(password, password2, passwordOld)
    .then(data => {
      commit('setLoading', false);
      return data;
    })
  },
  saveMessagesToken({commit}, {messagesToken, oldToken}) {
    commit('setLoading', true);
    return users.sendMessagesToken(messagesToken, oldToken)
    .then(data => {
      commit('setLoading', false);
      return data;
    })
  },
  getMailNotifications({commit}) {
    commit('setLoading', true);
    return users.getNotificationsMail()
    .then(data => {
      commit('setLoading', false);
      return data;
    })
  },
  getSettings({commit}) {
    commit('setLoading', true);
    return users.getSettings()
    .then(data => {
      if (typeof data.emailNotification !== 'undefined') {
        commit('setEmailNotification', parseInt(data.emailNotification));
      }
      if (typeof data['email-2fa'] !== 'undefined') {
        commit('setEmail2fa', parseInt(data['email-2fa']));
      }
      commit('setLoading', false);
      return data;
    })
  },
  saveMailNotifications({commit}, {emailNotification}) {
    commit('setLoading', true);
    return users.postNotificationsMail(emailNotification)
    .then(data => {
      if (typeof data.email !== 'undefined') {
        commit('setEmailNotification', parseInt(data.email));
      } 
      commit('setLoading', false);
      return data;
    })
  },
  saveEmail2fa({commit}, {email2fa}) {
    commit('setLoading', true);
    return users.postEmail2fa(email2fa)
    .then(data => {
      if (typeof data.email !== 'undefined') {
        commit('setEmail2fa', parseInt(data['email-2fa']));
      } 
      commit('setLoading', false);
      return data;
    })
  },
  deleteProfile({commit}) {
    commit('setLoading', true);
    return users.deleteProfile()
    .then(data => {
      commit('setLoading', false);
      return data;
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}