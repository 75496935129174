<template>
  <v-container
		v-if="loading && !menuIsReloaded"
    class="flex-column justify-center fill-height pt-0 app-container center"
		fluidx
	>
    <v-progress-circular
      :size="120"
      :width="7"
      color="primary"
      indeterminate
      class="install-progress"
    ></v-progress-circular>
  </v-container>
  <v-container
		v-else-if="authError !== false"
    class="fill-height flex-column justify-md-center"
		fluid
	>
		<div class="flex-grow-1 flex-md-grow-0 d-flex align-center w-100">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
          <div class="text-h5 text--primary mb-8">{{ locales['enter-password'] || 'Podaj hasło' }}</div>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            autocomplete="off"
          >
            <v-text-field 
              :label="locales['password']"
              :type="passType ? 'text' : 'password'" 
              v-model="passwd" 
              :rules="passwordRules"
              :append-icon="passType ? 'visibility' : 'visibility_off'" 
              @click:append="passType = !passType"
              required
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <div class="flex-shrink-1 h-action-column w-100 pt-md-12">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
          <v-btn x-large rounded color="primary" @click="login" :loading="loading" class="w-100 mb-2">{{ locales['login'] }}</v-btn>
        </v-col>
      </v-row>
    </div>
	</v-container>
  <v-container
		v-else-if="loadError"
    class="flex-column justify-center fill-height pt-0 app-container center"
		fluidx
	>
    <v-alert class="r-16 py-5">
      <div class="text-center px-5 pb-3">{{ locales['load-error'] }}</div>
      <div class="text-center">
        <v-btn rounded outlined color="primary" @click="load()">{{ locales['refresh'] }}</v-btn>
      </div>
    </v-alert>
  </v-container>
  <v-container
		v-else
    class="flex-columnx px-0 pt-0"
		fluid
	>
    <!--<v-fade-transition>-->
        <v-list v-if="menuLength > 0" class="nobg py-0" expand>
          <v-divider></v-divider>
          <template v-for="(item,i) in menu.items">
            <v-list-group v-if="item.items" :key="i" :value="item.expand=='1' || undefined">
              <template v-slot:activator>
                <v-list-item-title class="text-h6">{{ item.name }}</v-list-item-title>
              </template>
              <v-divider></v-divider>
              <template v-for="(subItem,j) in item.items">
                <v-list-group
                  sub-group
                  v-if="subItem.items"
                  :key="j"
                  :value="subItem.expand=='1' || undefined"
                  prepend-icon="$expand"
                  class="lvl-2"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ subItem.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-divider></v-divider>
                  <template v-for="(subSubItem,k) in subItem.items">
                    <Param :data="subSubItem" :key="k" />
                    <v-divider :key="'k'+k" v-if="k + 1 < subItem.items.length"></v-divider>
                  </template>
                </v-list-group>
                <Param v-else :data="subItem" :key="j" />
                <v-divider :key="'j'+j" v-if="j + 1 < item.items.length"></v-divider>
              </template>
            </v-list-group>
            <Param v-else :data="item" :key="i" />
            <v-divider :key="'i'+i"></v-divider>
          </template>
        </v-list>
    <!--</v-fade-transition>-->
    <ParamEdit />
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Param from '@/components/Param.vue'
import ParamEdit from '@/components/ParamEdit.vue'
export default {
  props: ['controllerId', 'menuName'],
  components: {
    Param, ParamEdit
  },
  data () {
    return {
      loading: true,
      loadError: false,
      authError: false,

      valid: false,
      passwd: '',
      passwordRules: [ v => !!v || (this.locales['enter-password'] ?? 'Podaj hasło') ],
      passType: false,
      menuIsReloaded: false
    }
  },
  computed: {
    ...mapGetters('menu', ['getMenu']),
    ...mapGetters('controllers', ['byId']),
    ...mapGetters('locales', ['locales']),
    ...mapGetters(['clock']),
    ...mapGetters('parameters', ['updateTime', 'inputTime', 'editDialog']),
    controllerById() {
      return this.byId(this.controllerId)
    },
    menu() {
      return this.getMenu(this.menuName)
    },
    menuLength() { 
      return (this.menu && this.menu.items) ? this.menu.items.length : 0
    }
  },
  watch: {
    clock() {
      this.getAll()
    },
    menuName() {
      this.load();
    },
    inputTime() {
      // refresh after save options/temporary parameters
      // dont refresh menu if edit dialog is open
      if (!this.editDialog) {
        this.menuIsReloaded = true; // to not collapse menu items opened by user during reload
        this.load();
      } 
    }
  },
  mounted() {
    if (!this.controllerById) {
      this.$router.push('/controllers')
    } else {
      this.load();
    }
  },
  beforeDestroy() {
    this.setPageTitle(undefined);
  },
  methods: {
    ...mapActions('menu', ['fetchMenu']),
    ...mapMutations(['setPageTitle']),
    ...mapActions('parameters', ['getAll']),
    login() {
      if (this.$refs.form.validate()) {
        this.load()
      }
    },
    load() {
      this.loading = true
      this.fetchMenu({
        name: this.menuName,
        controllerId: this.controllerId,
        password: this.passwd
      })
      .then(data => {
        if (data.error) {
          if (data.error.code == 300) {
            this.authError = data.error.code;
          } else {
            this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
            this.loadError = true;
          }
        } else {
          this.setPageTitle(this.menu.name || 'Menu');
          this.loadError = false;
          this.authError = false;
        }
        this.loading = false
      });
    },
    /*editParam(param) {
      this.paramDialog = true;
      if (param) { 
        // ok 
        }
    }*/
  }
}
</script>

<style>
.v-application--is-ltr .v-list-group--sub-group.lvl-2 .v-list-group__header {
    padding-left: 25px;
}
.v-list-group--sub-group.lvl-2 .v-list-group__header__prepend-icon {
    align-self: flex-end;
    margin: 16px 0px !important;
}
.v-list-group.lvl-2 .v-list-group__header .v-list-group__header__prepend-icon {
  order: 2;
}
.v-list-group.lvl-2 .v-list-group__header .v-list-item__content {
  order: 1;
}
</style>