<template>
  <v-list-item v-if="isParam" @click.stop="ed">
    <v-list-item-content>{{ config.label }}</v-list-item-content>
    <v-list-item-action class="flex-row" :class="{'c-primary': isEditable && !(parameter.disabled === '1' || parameter.disabled === 1) }">
      <span v-if="config.options">{{ config.options[paramValue(data.param)] }}</span>
      <span v-else-if="hasValue">{{ paramValue(data.param) | valueFilter }}</span>
      <span v-else-if="isEditable && config.prompt">{{ config.prompt }}</span>
      <v-icon v-else-if="isEditable" color="primary">edit</v-icon>
      <span v-if="config.unit == 'C' && hasValue">&deg;C</span>
      <span v-else-if="config.unit && hasValue" v-text="config.unit"></span>
    </v-list-item-action>
  </v-list-item>
  <v-list-item v-else>
    <v-list-item-content>{{ data.label }}</v-list-item-content>
    <v-list-item-action class="flex-row">
      <span v-if="data.value">{{ data.value }}</span>
      <span v-if="data.unit == 'C'">&deg;C</span>
      <span v-else-if="data.unit" v-text="config.unit"></span>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: ['data'],
  computed: {
    ...mapGetters('profile', ['locale']),
    ...mapGetters('parameters', ['param', 'paramValue', 'paramConfig']),
    parameter() {
      return this.param(this.data.param)
    },
    isParam() {
      return !!this.data.param
    },
    hasValue() {
      /* if (typeof this.data !== 'undefined') {
        console.log(typeof this.paramValue(this.data.param))
      } */
      
      return typeof this.data !== 'undefined' && (typeof this.paramValue(this.data.param) === 'string' || typeof this.paramValue(this.data.param) === 'number') && this.paramValue(this.data.param) !== ''
      // return typeof this.data !== 'undefined' && ()
      // typeof paramValue(data.param) === 'string' || typeof paramValue(data.param) === 'number'
    },
    config() {
      return this.paramConfig(this.data.param)
    },
    setConfig() {
      return (this.config.setParam) ? this.paramConfig(this.config.setParam) : {}
    },
    setterUrl() {
      return (this.data.value_set) ? `/controllers/${this.controllerId}/${this.data.id}` : null
    },
    isEditable() {
      return +this.config.edit === 1
    },
    isDisabled() {
      return Object.prototype.hasOwnProperty.call(this.parameter, "disabled") && +this.parameter.disabled === 1
    },
    isConfigAlert() {
      return Object.prototype.hasOwnProperty.call(this.config, "alert") && this.config.alert !== ''
    }
  },
  methods: {
    ...mapMutations('parameters', ['editDialogOpen']),
    ed() {
      if (this.isDisabled) { 
        if (this.parameter.alert) {
          this.$store.commit('snack/open', { color: "error", text: this.parameter.alert });
        }
      } else if (!this.isEditable && this.isConfigAlert) {
        this.$store.commit('snack/open', { color: "error", text: this.config.alert });
      } else if (this.isEditable) {
        this.editDialogOpen(this.data.param);
      } else if (this.config.setParam) {
        this.editDialogOpen(this.config.setParam);
      }
    }
  },
  filters: {
    valueFilter: function (value) {
      if (value == 0) { return 0 }
      else if (!value) return ''
      if (isNaN(value)) {
        return value
      } else {
        return (value.toString().indexOf('.') > -1) ? parseFloat(value).toLocaleString() : value;
      }
    },
  }
}
</script>
