import Vue from 'vue'
import VueRouter from 'vue-router'
import Onboarding from '../views/Onboarding.vue'
import Login from '../views/Login.vue'
import Login2Fa from '../views/Login2Fa.vue'
import Remind from '../views/Remind.vue'
import Register from '../views/Register.vue'
import Newpass from '../views/Newpass.vue'
import Confirm from '../views/Confirm.vue'

import Controllers from '../views/Controllers.vue'
import Controller from '../views/Controller.vue'
import ControllerUsers from '../views/ControllerUsers.vue'
import ControllerAlarms from '../views/ControllerAlarms.vue'
import Menu from '../views/Menu.vue'
import ControllerStatistics from '../views/ControllerStatistics2.vue'

import Settings from '../views/Settings.vue'
import Changepass from '../views/Changepass.vue'

Vue.use(VueRouter)

const routes = [
  { 
    path: '/index.html', redirect: '/' 
  },
  {
    path: '/',
    component: Onboarding,
    meta: {
      level: 1,
      nav: 0
    }
  },
  {
    path: '/login',
    component: Login,
    meta: {
      level: 2,
      nav: 1
    }
  },
  {
    path: '/login/2fa',
    component: Login2Fa,
    meta: {
      level: 2,
      nav: 1
    }
  },
  {
    path: '/login/remind',
    component: Remind,
    meta: {
      level: 3,
      nav: 1
    }
  },
  {
    path: '/register',
    component: Register,
    meta: {
      level: 2,
      nav: 1
    }
  },
  {
    path: '/controllers',
    component: Controllers,
    meta: {
      level: 2,
      nav: 2,
      refresh: 'controllers',
      background: true
    }
  },
  {
    path: '/controllers/:controllerId',
    component: Controller,
    props: true,
    meta: {
      level: 3,
      nav: 2,
      title: true,
      refresh: 'controller',
      background: true
    }
  },
  {
    path: '/controllers/:controllerId/users/:pathpart?',
    component: ControllerUsers,
    props: true,
    meta: {
      level: 4,
      nav: 1,
      title: true
    }
  },
  {
    path: '/controllers/:controllerId/statistics/:pathpart?',
    component: ControllerStatistics,
    props: true,
    meta: {
      level: 4,
      nav: 1,
      title: true
    }
  },
  {
    path: '/controllers/:controllerId/alarms/:pathpart?',
    component: ControllerAlarms,
    props: true,
    meta: {
      level: 4,
      nav: 1,
      title: true
    }
  },
  {
    path: '/controllers/:controllerId/menu/:menuName',
    component: Menu,
    props: true,
    meta: {
      level: 4,
      nav: 1,
      title: true
    }
  },
  {
    path: '/settings',
    component: Settings,
    meta: {
      level: 4,
      nav: 1,
      title: true
    }
  },
  {
    path: '/new-pass/:token',
    component: Newpass,
    props: true,
    meta: {
      level: 2,
      nav: 1
    }
  },
  {
    path: '/change-pass',
    component: Changepass,
    meta: {
      level: 4,
      nav: 1,
      title: true
    }
  },
  {
    path: '/confirm/:token',
    component: Confirm,
    props: true,
    meta: {
      level: 2,
      nav: 1
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const profile = localStorage.getItem('profile');
  var token = undefined;

  if (profile) {
    token = JSON.parse(profile).token;
  }
  if (to.path == '/' && token) {
    return next('/controllers');
  }
  
  const publicPages = ['/', '/login', '/login/2fa', '/login/remind', '/register'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !token) {
    return next('/login');
  }

  next();
})



export default router
