<template>
  <v-card-text class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-column flex-grow-1 justify-center">
      <div v-if="config.prompt" class="text-center pb-4">{{ config.prompt }}</div>
      
      <div v-if="dataComplete" class="d-flex flex-column align-center" v-bind:class="{invalid: !valueValid}">
        <div class="d-flex flex-wrap justify-center w-100 w-60-l pt-2">
          
          <div class="d-flex flex-column align-center w-third">
            <div class="mb-2">
              Godzina
            </div>
            <div class="spec-picker">
              <v-btn x-large icon :color="(valueValid) ? 'primary' : 'error'" @click="decreaseH" :disabled="hourSet == '00'">
                <v-icon>remove</v-icon>
              </v-btn>
              <div class="select-wrapper">
                <v-select
                  v-model="hourSet"
                  :items="hourItems"
                  hide-details
                  class="setter text-h5"
                  outlinedx
                  :menu-props="{ auto: true, 'content-class': 'setter-menu' }"
                  append-icon=""
                ></v-select>
              </div>
              <v-btn x-large icon :color="(valueValid) ? 'primary' : 'error'" @click="increaseH" :disabled="this.minuteSet == '23'">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </div>
          
          <div class="d-flex flex-column align-center w-third relative">
            <div class="mb-2">
              Minuta
            </div>
            <div class="spec-picker">
              <v-btn x-large icon :color="(valueValid) ? 'primary' : 'error'" @click="decreaseM" :disabled="minuteSet == '00'">
                <v-icon>remove</v-icon>
              </v-btn>
              <div class="select-wrapper">
                <v-select
                  v-model="minuteSet"
                  :items="minuteItems"
                  color="primary"
                  hide-details
                  class="setter"
                  :menu-props="{ auto: true, 'content-class': 'setter-menu' }"
                  append-icon=""
                ></v-select>
              </div>
              <v-btn x-large icon :color="(valueValid) ? 'primary' : 'error'" @click="increaseM" :disabled="this.minuteSet == '59'">
                <v-icon>add</v-icon>
              </v-btn>
            </div>
            <div class="time-dots">:</div>
          </div>

        </div>
        <div v-if="minValue || maxValue" class="pt-6" v-bind:class="{blink: !valueValid}">Zakres: {{minValue}} - {{maxValue}}</div>
      </div>
      
    </div>
    <v-card-actions class="flex-shrink-1 pb-5 pb-sm-0 px-3 px-sm-0" :class="{'justify-center': isMobile, 'justify-end': !isMobile}">
      <v-btn x-large rounded text exact :color="(isMobile) ? 'primary' : 'secondary'" @click="close" class="mr-1">{{ locales['cancel'] }}</v-btn>
      <v-btn x-large rounded :text="!isMobile" color="primary" @click="save" :disabled="!valueValid" :loading="loading" class="px-16 px-md-6">{{ locales['save'] }}</v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['paramName','dialogTimer'],
  data: () => ({
		valid: true,
    empty: false,
    loading: false,
    rules: {
      required: value => !!value || 'Wypełnij to pole'
    },
    hourItems: ['00', '01', '02', '03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
    hourSet: '',
    minuteItems: ['00', '01', '02', '03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'],
    minuteSet: '',
	}),
	computed: {
    ...mapGetters('parameters', ['editDialog', 'paramValue', 'paramConfig']),
    ...mapGetters(['isMobile']),
    ...mapGetters('locales', ['locales']),
    config() {
      return (this.paramName) ? this.paramConfig(this.paramName) : {}
    },
    value_set() {
      return this.hourSet + ':' + this.minuteSet
    },
    minValue() {
      return this.config.min ?? false
    },
    maxValue() {
      return this.config.max ?? false
    },
    dataComplete() {
      return (typeof this.minValue !== 'undefined' && typeof this.maxValue !== 'undefined' && typeof this.value_set !== 'undefined')
    },
    minTimestamp() {
      return (this.minValue) ? Date.parse('2000/01/01 ' + this.minValue + ':00') : null;
    },
    maxTimestamp() {
      return (this.maxValue) ? Date.parse('2000/01/01 ' + this.maxValue + ':00') : null;
    },
    valueValid(){
      if (this.minValue || this.maxValue) {
        
        const setTimestamp = Date.parse('2000/01/01 ' + this.hourSet + ':' + this.minuteSet +  ':00');
        
        if (setTimestamp >= this.minTimestamp && setTimestamp <= this.maxTimestamp) {
          return true
        }
        
        return false
      }

      if (!this.value_set) {
        return false
      }

      return true
    }
  },
  watch: {
    paramName() {
      this.setup()
    },
    dialogTimer() {
      this.setup()
    }
  },
  created() {
    this.setup()
  },
	methods: {
    ...mapMutations('parameters', ['editDialogClose', 'setParameter']),
    ...mapActions('parameters', ['update']),
    setup () {
      // set values
      const valSplitted = this.paramValue(this.paramName).split(':');
      let h = valSplitted[0];
      let m = valSplitted[1];
      
      if (m && m.length === 1) {
        m = '0' + m;
      }
      this.minuteSet = m;

      if (h && h.length === 1) {
        h = '0' + h;
      }
      this.hourSet = h;
      
      // this.value_set = this.paramValue(this.paramName)
    },
    close () {
      this.loading = false;
      this.editDialogClose();
    },
    save () {
      if (this.valueValid) {
        this.loading = true;
        this.update({
          paramName: this.paramName,
          paramValue: this.value_set
        })
        .then(data => {
          if (data.error) {
            this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
            this.loading = false;
          } else {
            if (data[this.paramName]) {
              this.$store.commit('snack/open', { color: "success", timeout: 700, text: this.locales['changes-saved'] })
              //this.setParameter({name: this.paramName, value: this.value_set});
              this.setParameter({name: this.paramName, value: data[this.paramName]})
              this.close()
            } else {
              this.$store.commit('snack/open', { color: "error", timeout: 5000, text: this.locales['no-value'] + ': ' + this.paramName })
              this.loading = false
            }
          }
        })
      }
    },
    increaseH() {
      let index = this.hourItems.findIndex(el => el == this.hourSet);
      if (index < this.hourItems.length - 1) {
        this.hourSet = this.hourItems[index + 1];
      }
    },
    decreaseH() {
      let index = this.hourItems.findIndex(el => el == this.hourSet);
      if (index > 0) {
        this.hourSet = this.hourItems[index - 1];
      }
    },
    increaseM() {
      let index = this.minuteItems.findIndex(el => el == this.minuteSet);
      if (index < this.minuteItems.length - 1) {
        this.minuteSet = this.minuteItems[index + 1];
      }
    },
    decreaseM() {
      let index = this.minuteItems.findIndex(el => el == this.minuteSet);
      if (index > 0) {
        this.minuteSet = this.minuteItems[index - 1];
      }
    },
	}
}
</script>

<style scoped>
.option-list .v-list-item {
  border: 2px solid transparent;
}
.option-list .v-list-item.listactive {
  border: 2px solid #2196f3;
}
.option-list .listactive.v-list-item--active::before {
  opacity: 0;
}
.v-list-item__title {
  font-size: 1.25rem;
}
.v-list:not(.v-list--dense) .listactive .v-list-item__title {
  font-weight: 700;
  font-size: 1.5rem;
}

.option-list .v-list-item--dense, 
.v-list--dense.option-list .v-list-item {
  min-height: 32px;
}
.option-list .v-list-item--dense .v-list-item__content, 
.v-list--dense.option-list .v-list-item .v-list-item__content {
  padding: 4px 0;
}

</style>