<template>
  <v-container
		v-if="installing"
    class="flex-column justify-center fill-height pt-0 app-container center"
	>
    <v-progress-circular
      :size="120"
      :width="7"
      color="primary"
      indeterminate
      class="install-progress"
    ></v-progress-circular>
  </v-container>
  <v-container
		v-else-if="installError"
    class="flex-column justify-center fill-height pt-0 app-container center"
	>
    <v-alert class="r-16">
      <div class="text-center px-5 pb-3">{{ installErrorMessages[installError] }}</div>
      <div class="text-center">
        <v-btn rounded outlined color="primary" @click="setup">{{ locales['refresh'] }}</v-btn>
      </div>
    </v-alert>
  </v-container>
  <v-container
		v-else
    class="flex-column pt-0 app-container center"
	>
    <!--<v-fade-transition>-->
    <v-row dense>
      <v-col v-for="(item, i) in menu.items" :key="i" :cols="(item.type == 'rekuperator' || item.size == 2) ? 12 : 6">
        <!--<gauge :controllerId="controllerId" :data="item" />-->
        <gauge v-if="!item.type" :controllerId="controllerId" :data="item" />
        <gauge-recuperator v-else-if="item.type == 'rekuperator'" :controllerId="controllerId" :data="item" />
      </v-col>
    </v-row>
    <!--</v-fade-transition>-->
    <!--<controller-debug :menu="menu" />-->
    <ParamEdit :onRefresh="onRefreshHandler" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Gauge from '@/components/Gauge.vue';
import GaugeRecuperator from '@/components/GaugeRecuperator.vue';
import ParamEdit from '@/components/ParamEdit.vue'
//import ControllerDebug from '@/components/ControllerDebug.vue'
export default {
  props: ['controllerId'],
  components: {
    Gauge, ParamEdit, GaugeRecuperator
  },
  data () {
    return {
      installing: false,
      valid: true,
      debug: true,
      installErrorMessages: {
        1: 'Nie udało się pobrać konfiguracji parametrów.',
        2: 'Nie udało się pobrać menu urządzenia.',
        3: 'Nie udało się pobrać parametrów urządzenia.'
      }
    }
  },
  computed: {
    ...mapGetters('controllers', ['byId']),
    ...mapGetters('locales', ['locales']),
    ...mapGetters('menu', ['getMenu']),
    ...mapGetters('parameters', ['currentDevice', 'config', 'all']),
    ...mapGetters(['clock']),
    controllerById() {
      return this.byId(this.controllerId)
    },
    menu() {
      return this.getMenu('sterownik')
    },
    installError() {
      if (this.installing) {
        return null
      } else if (Object.keys(this.config).length == 0) {
        return 1
      } else if (!this.menu.items) {
        return 2
      } else if (Object.keys(this.all).length == 0) {
        return 3
      } else {
        return null
      }
    },
  },
  watch: {
    clock() {
      this.getAll()
    }
  },
  created() {
    if (!this.controllerById) {
      //page reload - restore controllers from local storage
      this.restore()
      .then(() => {
        if (!this.controllerById) {
          this.$router.push('/controllers');
        } else {
          this.setCurrentDevice(this.controllerId);
          this.setup();
        }
      })
    } else if (this.currentDevice != this.controllerId) {
      this.setCurrentDevice(this.controllerId);
      this.setup();
    } 
  },
  mounted() {
    this.setPageTitle((this.controllerById) ? this.controllerById.displayName : this.controllerId);
  },
  beforeDestroy() {
    this.setPageTitle(undefined);
  },
  methods: {
    ...mapActions('controllers', ['restore']),
    ...mapMutations(['setPageTitle']),
    ...mapActions('parameters', ['getAll','getConfig','setCurrentDevice']),
    ...mapActions('menu', ['fetchMenu']),
    setup() {
      this.installing = true;
      this.getAll()
      .then((data) => {
        if (data.error) {
          throw data;
        } else {
          return this.getConfig()
        }
      })
      .then((data) => {
        if (data.error) {
          throw data;
        } else {
          return this.fetchMenu({
            name: 'sterownik',
            controllerId: this.controllerId
          });
        }
      })
      .then((data) => {
        if (data.error) {
          throw data;
        } else {
          return this.fetchMenu({
            name: 'menu-lewe',
            controllerId: this.controllerId
          });
        }
      })
      .then((data) => {
        if (data.error) {
          throw data;
        }
        // this.startListener();
        this.installing = false;
      })
      .catch(data => {
        this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
        this.valid = true;
        this.installing = false;
      });
    },
    onRefreshHandler() {
      return this.fetchMenu({
        name: 'sterownik',
        controllerId: this.controllerId
      });
    }
  }
}
</script>

