<template>
	<v-container
		class="fill-height flex-column justify-md-center"
		fluid
	>
		<div class="flex-grow-1 flex-md-grow-0 d-flex align-center w-100">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
          <div class="text-h5 text--primary mb-8">{{ locales['enter-code'] }}</div>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            autocomplete="off"
          >
            <v-text-field
              v-model="code" 
              :rules="codeRules"
              required 
              name="code"
              type="text"
              outlined
            ></v-text-field>
          </v-form>
          <div class="text--primary">{{ locales['info-2fa'] }}</div>
        </v-col>
      </v-row>
    </div>
    <div class="flex-shrink-1 h-action-column w-100 pt-md-12">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="10"
          sm="8"
          md="6"
          lg="4"
        >
          <v-btn x-large rounded color="primary" @click="loginUser" :loading="loading" class="w-100 mb-2">{{ locales['login'] }}</v-btn>
        </v-col>
      </v-row>
    </div>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	data: () => ({
		valid: true,
		code: undefined,
		codeRules: [ v => !!v || 'Podaj kod' ],
		errorMessage: false
	}),
	computed: {
		...mapGetters('profile', ['loading']),
    ...mapGetters('locales', ['locales', 'locale']),
  },
	methods: {
		...mapActions('profile', ['login2fa']),
		loginUser() {
      if (this.$refs.form.validate()) {
				this.login2fa({
					code: this.code,
				})
				.then(data => {
					if (data.error) {
						this.$store.commit('snack/open', { color: "error", text: this.locales['err'] + '! ' + (this.locales['error-' + data.error.code] || '') });
					} else {
            this.$router.push({ path: '/controllers' })
					}
				});
			}
		}
	}
}
</script>

<style>
.v-card.no-bg {
	background-color: transparent !important;
}
</style>